import MargettsAriaHelpers from '../modules/aria';
import MargettsDomHelpers from '../modules/dom';

// POST links

const postLinks = document.getElementsByClassName('js-post');

[].forEach.call(postLinks, postLink => {
    postLink.addEventListener('click', event => {
        event.preventDefault();
        document.getElementById(postLink.getAttribute('data-form')).submit();
    });
});

// Menu

const expandMenu = menu => {
    MargettsDomHelpers.showElement(document.getElementById(menu.getAttribute('aria-controls')));
    menu.setAttribute('aria-expanded', 'true');
};

const collapseMenu = menu => {
    MargettsDomHelpers.hideElement(document.getElementById(menu.getAttribute('aria-controls')));
    menu.setAttribute('aria-expanded', 'false');
};

const menus = document.getElementsByClassName('js-menu');

[].forEach.call(menus, menu => {
    menu.addEventListener('click', event => {
        event.preventDefault();

        const target = event.currentTarget;

        if (! MargettsAriaHelpers.isExpanded(target)) {
            [].forEach.call(menus, otherMenu => {
                if (! otherMenu.parentNode.contains(target)) {
                    collapseMenu(otherMenu);
                }
            });

            document.body.addEventListener('click', bodyNavigationListener, true);
            expandMenu(target);
        } else {
            document.body.removeEventListener('click', bodyNavigationListener);
            collapseMenu(target);
        }
    });
});

const bodyNavigationListener = event => {
    let shouldCollapseMenus = true;

    [].forEach.call(menus, menu => {
        if (menu.parentNode.contains(event.target)) {
            shouldCollapseMenus = false;
        }
    });

    if (shouldCollapseMenus) {
        [].forEach.call(menus, menu => {
            if (MargettsAriaHelpers.isExpanded(menu)) {
                collapseMenu(menu);
            }
        });

        document.body.removeEventListener('click', bodyNavigationListener);
    }
};

// Synopsis links

const synopsisLinks = document.getElementsByClassName('js-synopsis');

[].forEach.call(synopsisLinks, synopsisLink => {
    synopsisLink.addEventListener('mouseover', event => {
        [].forEach.call(document.getElementsByClassName('synopsis-list-item'), synopsisListItem => {
            MargettsDomHelpers.hideElement(synopsisListItem);
        });

        const synopsis = document.getElementById(event.target.getAttribute('data-synopsis-id'));

        if (synopsis) {
            MargettsDomHelpers.showElement(synopsis);
        }
    })
});

// Back links

const backLinks = document.getElementsByClassName('js-to-back');

if (window.history.length <= 1) {
    [].forEach.call(backLinks, backLink => {
        backLink.remove();
    });
} else {
    [].forEach.call(backLinks, backLink => {
        backLink.addEventListener('click', event => {
            event.preventDefault();
            history.back();
        });
    });
}

// Scroll links

const scrollTo = top => {
    window.scroll({
        top: top,
        left: 0,
        behavior: 'smooth'
    });
};

// Scroll to top and header

const header = document.querySelector('#app > header');
const scrollToTopLinks = document.getElementsByClassName('js-to-top');

[].forEach.call(scrollToTopLinks, scrollToTopLink => {
    scrollToTopLink.addEventListener('click', event => {
        event.preventDefault();
        scrollTo(0);
    });
});

const scrollObserver = new IntersectionObserver(entities => {
    entities.forEach(entity => {
        if (entity.isIntersecting) {
            [].forEach.call(scrollToTopLinks, scrollToTopLink => {
                MargettsDomHelpers.hideElement(scrollToTopLink);
            });

            header.classList.remove('compact');
        } else {
            [].forEach.call(scrollToTopLinks, scrollToTopLink => {
                MargettsDomHelpers.showElement(scrollToTopLink);
            });

            header.classList.add('compact');
        }
    });
});

const observableEntities = document.querySelector('h1, h2, .component-image-slider');

if (observableEntities) {
    scrollObserver.observe(observableEntities);
}

// Scroll to anchors

const links = document.getElementById('app').getElementsByTagName('a');

[].forEach.call(links, link => {
    if (link.getAttribute('role') === 'tab') {
        return;
    }

    const href = link.getAttribute('href');

    if (href && href.charAt(0) === '#') {
        link.addEventListener('click', event => {
            const target = document.getElementById(href.substr(1));

            if (target) {
                event.preventDefault();
                const targetTop = target.getBoundingClientRect().top + window.scrollY - document.querySelector('#app > header').offsetHeight;
                scrollTo(targetTop);
            }
        });
    }
});

// Show passwords

const passwordToggleButtons = document.getElementsByClassName('js-show-password');

[].forEach.call(passwordToggleButtons, passwordToggleButton => {
    passwordToggleButton.addEventListener('click', event => {
        event.preventDefault();
        const passwordField = passwordToggleButton.parentElement.querySelector('.is-password-field');
        passwordField.type = passwordField.type === 'password' ? 'text' : 'password';
    });
});
